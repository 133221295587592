<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
        <b-button type="button" variant="lisaweb" size="sm" @click="openUrl(3)">
          Carica Scansioni
        </b-button>
        <b-button type="button" variant="lisaweb" size="sm" @click="openUrl(2)">
          Report
        </b-button>
        <b-button type="button" variant="lisaweb" size="sm" @click="openUrl(1)">
          Errori
        </b-button>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <div class="mt-2">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="RFRNAME"
                  v-model="filter.byRegistry.title"
                  type="text"
                  label="Nominativo"
                  placeholder="Inserisci un nominativo"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="GDPR"
                  v-model="filter.byAttribute.GDPR"
                  type="text"
                  label="Stato Raccolta Privacy"
                  :options="statusPrivacyOptions"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="elenca"
                  label="Stato Portafoglio"
                  v-model="filter.byInsuranceStatus.value"
                  :options="statusInsuranceOptions"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                />
              </div>
              <div class="form-group col-md-3 align-self-end">
                <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
        </b-form>
      </div>
    </b-collapse>

    <!-- <button-group-table
      @csv="onExport('csv')"
      :options="{
        CSV: { enabled: true },
      }"
    ></button-group-table> -->

    <table-registries
      @edit="onEdit"
      @destroy="onDestroy"
      @rowSelector2="onPrint"
      @rowSelector3="onDownload"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{
        byRelations: ['byRegistryGroup', 'byInsurancePolicy', 'byDocument'],
      }"
      :onlyActions="['edit', 'infomodal', 'destroy']"
      noCustom
      noActions
    ></table-registries>
    <!-- <note-detail-modal :items="notes"> </note-detail-modal>
    <document-detail-modal :items="documents"> </document-detail-modal> -->
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import TableRegistries from "@/components/tables/Registries";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
// import BaseTextarea from "@/components/form/BaseTextarea";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions } from "vuex";
// import CustomFilter from "@/components/form/CustomFilter";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import DocumentsMixin from "@/mixins/DocumentsMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";
import { mapGetters } from "vuex";
import { toInfoData } from "@/utils/transforms";
// import NoteDetailModal from "@/components/modals/noteDetailModal";
// import DocumentDetailModal from "@/components/modals/documentDetailModal";
import { getInputType } from "@/utils/forms";
// import { canVerb } from "@/utils/permissions";
// import { getDictionary } from "@/utils/dictionary";

export default {
  mixins: [
    CustomFiltersMixin,
    DocumentsMixin,
    StorageGetterMixin,
    /* ExportsMixin */
  ],
  data() {
    return {
      defaultCountryId: null,
      defaultCountryName: "Italia",
      opt_provinces: [],
      opt_councils: [],
      ajaxOptions: [],
      isLoadingAjax: false,
      repository: "registry",
      resource: "registries",
      filterName: "filterPrivEasyRegistry",
      optProvince: null,
      filter: this.initFilter(),
      // notes: [],
      // documents: [],
      salesmen: [],
      statusRegistryOptions: [
        { text: "Persona Fisica", value: 0 },
        { text: "Persona Giuridica", value: 1 },
      ],
      // "0":"Consenso Non Raccolto",
      // "1":"Consenso Raccolto",
      // "2":"Consenso Revocato"
      statusPrivacyOptions: [
        { text: "Consenso Non Raccolto", value: 0 },
        { text: "Consenso Raccolto", value: 1 },
        { text: "Consenso Revocato", value: 2 },
      ],
      statusInsuranceOptions: [
        { value: "yes", text: "Clienti con polizze attive" },
        { value: "no", text: "Clienti con polizze estinte" },
        { value: "without", text: "Clienti senza polizze" },
      ],
      tableRef: "PrivEasyRegistryTableRef",
      fields: [
        {
          key: "rowSelector2",
          label: "Stampa",
          sortable: false,
          thStyle: {
            width: "40px",
          },
        },
        {
          key: "rowSelector3",
          label: "Doc",
          sortable: false,
          thStyle: {
            width: "40px",
          },
        },
        {
          key: "customer",
          label: this.getDictionary("customer_name"),
          sortable: true,
          sortKey: "title",
        },
        {
          key: "status_registry",
          label: this.getDictionary("status_registry", "registry"),
          sortable: false,
        },
        {
          key: "primary_group",
          label: this.getDictionary("Gruppo Primario"),
        },
        {
          key: "address",
          label: this.getDictionary("attribute_ADDR_value", "correspondence"),
          sortable: false,
        },
        {
          key: "address_books",
          label: this.getDictionary("address_book"),
          value: (item) => toInfoData(item, "address_books", null),
          sortable: false,
        },
        {
          key: "insurance_policy",
          label: this.getDictionary("insurance_policies"),
        },
        {
          key: "attributables.GDPR",
          label: this.getDictionary("attribute_GDPR_value", "registry"),
          sortable: true,
          sortKey: "byAttribute.GDPR",
        },
        // {
        //   key: "document_counter",
        //   label: this.getDictionary("Documenti"),
        //   value: (item) => (item.documents.length ? item.documents.length : 0),
        // },
      ],
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    // BaseTextarea,
    TableRegistries,
    // ButtonGroupTable,
    // NoteDetailModal,
    // DocumentDetailModal,
    // CustomFilter,
  },
  created() {
    /*  */
    console.log("beforeCreate Registries");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("countries"))
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {
            console.info(
              `background data "${path}countries" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("councils"))
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {
            console.info(`background data "${path}councils" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("provinces"))
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {
            console.info(
              `background data "${path}provinces" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /*  */
  },
  mounted() {},
  methods: {
    getInputType,
    // canVerb,
    // getDictionary,
    initFilter() {
      let init = {
        byAttribute: {
          id: null,
          GDPR: null,
        },
        byRegistry: {
          title: null,
        },
        byInsuranceStatus: {
          value: null,
        },
      };
      return init;
    },
    openUrl(type, target = "_blank", options = "noopener,noreferrer") {
      // standalone md5 calculus (no npm package)
      var MD5 = function (d) {
        var r = M(V(Y(X(d), 8 * d.length)));
        return r.toLowerCase();
      };
      function M(d) {
        for (var _, m = "0123456789ABCDEF", f = "", r = 0; r < d.length; r++)
          (_ = d.charCodeAt(r)),
            (f += m.charAt((_ >>> 4) & 15) + m.charAt(15 & _));
        return f;
      }
      function X(d) {
        for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0;
        for (m = 0; m < 8 * d.length; m += 8)
          _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32;
        return _;
      }
      function V(d) {
        for (var _ = "", m = 0; m < 32 * d.length; m += 8)
          _ += String.fromCharCode((d[m >> 5] >>> m % 32) & 255);
        return _;
      }
      function Y(d, _) {
        (d[_ >> 5] |= 128 << _ % 32), (d[14 + (((_ + 64) >>> 9) << 4)] = _);
        for (
          var m = 1732584193,
            f = -271733879,
            r = -1732584194,
            i = 271733878,
            n = 0;
          n < d.length;
          n += 16
        ) {
          var h = m,
            t = f,
            g = r,
            e = i;
          (f = md5_ii(
            (f = md5_ii(
              (f = md5_ii(
                (f = md5_ii(
                  (f = md5_hh(
                    (f = md5_hh(
                      (f = md5_hh(
                        (f = md5_hh(
                          (f = md5_gg(
                            (f = md5_gg(
                              (f = md5_gg(
                                (f = md5_gg(
                                  (f = md5_ff(
                                    (f = md5_ff(
                                      (f = md5_ff(
                                        (f = md5_ff(
                                          f,
                                          (r = md5_ff(
                                            r,
                                            (i = md5_ff(
                                              i,
                                              (m = md5_ff(
                                                m,
                                                f,
                                                r,
                                                i,
                                                d[n + 0],
                                                7,
                                                -680876936
                                              )),
                                              f,
                                              r,
                                              d[n + 1],
                                              12,
                                              -389564586
                                            )),
                                            m,
                                            f,
                                            d[n + 2],
                                            17,
                                            606105819
                                          )),
                                          i,
                                          m,
                                          d[n + 3],
                                          22,
                                          -1044525330
                                        )),
                                        (r = md5_ff(
                                          r,
                                          (i = md5_ff(
                                            i,
                                            (m = md5_ff(
                                              m,
                                              f,
                                              r,
                                              i,
                                              d[n + 4],
                                              7,
                                              -176418897
                                            )),
                                            f,
                                            r,
                                            d[n + 5],
                                            12,
                                            1200080426
                                          )),
                                          m,
                                          f,
                                          d[n + 6],
                                          17,
                                          -1473231341
                                        )),
                                        i,
                                        m,
                                        d[n + 7],
                                        22,
                                        -45705983
                                      )),
                                      (r = md5_ff(
                                        r,
                                        (i = md5_ff(
                                          i,
                                          (m = md5_ff(
                                            m,
                                            f,
                                            r,
                                            i,
                                            d[n + 8],
                                            7,
                                            1770035416
                                          )),
                                          f,
                                          r,
                                          d[n + 9],
                                          12,
                                          -1958414417
                                        )),
                                        m,
                                        f,
                                        d[n + 10],
                                        17,
                                        -42063
                                      )),
                                      i,
                                      m,
                                      d[n + 11],
                                      22,
                                      -1990404162
                                    )),
                                    (r = md5_ff(
                                      r,
                                      (i = md5_ff(
                                        i,
                                        (m = md5_ff(
                                          m,
                                          f,
                                          r,
                                          i,
                                          d[n + 12],
                                          7,
                                          1804603682
                                        )),
                                        f,
                                        r,
                                        d[n + 13],
                                        12,
                                        -40341101
                                      )),
                                      m,
                                      f,
                                      d[n + 14],
                                      17,
                                      -1502002290
                                    )),
                                    i,
                                    m,
                                    d[n + 15],
                                    22,
                                    1236535329
                                  )),
                                  (r = md5_gg(
                                    r,
                                    (i = md5_gg(
                                      i,
                                      (m = md5_gg(
                                        m,
                                        f,
                                        r,
                                        i,
                                        d[n + 1],
                                        5,
                                        -165796510
                                      )),
                                      f,
                                      r,
                                      d[n + 6],
                                      9,
                                      -1069501632
                                    )),
                                    m,
                                    f,
                                    d[n + 11],
                                    14,
                                    643717713
                                  )),
                                  i,
                                  m,
                                  d[n + 0],
                                  20,
                                  -373897302
                                )),
                                (r = md5_gg(
                                  r,
                                  (i = md5_gg(
                                    i,
                                    (m = md5_gg(
                                      m,
                                      f,
                                      r,
                                      i,
                                      d[n + 5],
                                      5,
                                      -701558691
                                    )),
                                    f,
                                    r,
                                    d[n + 10],
                                    9,
                                    38016083
                                  )),
                                  m,
                                  f,
                                  d[n + 15],
                                  14,
                                  -660478335
                                )),
                                i,
                                m,
                                d[n + 4],
                                20,
                                -405537848
                              )),
                              (r = md5_gg(
                                r,
                                (i = md5_gg(
                                  i,
                                  (m = md5_gg(
                                    m,
                                    f,
                                    r,
                                    i,
                                    d[n + 9],
                                    5,
                                    568446438
                                  )),
                                  f,
                                  r,
                                  d[n + 14],
                                  9,
                                  -1019803690
                                )),
                                m,
                                f,
                                d[n + 3],
                                14,
                                -187363961
                              )),
                              i,
                              m,
                              d[n + 8],
                              20,
                              1163531501
                            )),
                            (r = md5_gg(
                              r,
                              (i = md5_gg(
                                i,
                                (m = md5_gg(
                                  m,
                                  f,
                                  r,
                                  i,
                                  d[n + 13],
                                  5,
                                  -1444681467
                                )),
                                f,
                                r,
                                d[n + 2],
                                9,
                                -51403784
                              )),
                              m,
                              f,
                              d[n + 7],
                              14,
                              1735328473
                            )),
                            i,
                            m,
                            d[n + 12],
                            20,
                            -1926607734
                          )),
                          (r = md5_hh(
                            r,
                            (i = md5_hh(
                              i,
                              (m = md5_hh(m, f, r, i, d[n + 5], 4, -378558)),
                              f,
                              r,
                              d[n + 8],
                              11,
                              -2022574463
                            )),
                            m,
                            f,
                            d[n + 11],
                            16,
                            1839030562
                          )),
                          i,
                          m,
                          d[n + 14],
                          23,
                          -35309556
                        )),
                        (r = md5_hh(
                          r,
                          (i = md5_hh(
                            i,
                            (m = md5_hh(m, f, r, i, d[n + 1], 4, -1530992060)),
                            f,
                            r,
                            d[n + 4],
                            11,
                            1272893353
                          )),
                          m,
                          f,
                          d[n + 7],
                          16,
                          -155497632
                        )),
                        i,
                        m,
                        d[n + 10],
                        23,
                        -1094730640
                      )),
                      (r = md5_hh(
                        r,
                        (i = md5_hh(
                          i,
                          (m = md5_hh(m, f, r, i, d[n + 13], 4, 681279174)),
                          f,
                          r,
                          d[n + 0],
                          11,
                          -358537222
                        )),
                        m,
                        f,
                        d[n + 3],
                        16,
                        -722521979
                      )),
                      i,
                      m,
                      d[n + 6],
                      23,
                      76029189
                    )),
                    (r = md5_hh(
                      r,
                      (i = md5_hh(
                        i,
                        (m = md5_hh(m, f, r, i, d[n + 9], 4, -640364487)),
                        f,
                        r,
                        d[n + 12],
                        11,
                        -421815835
                      )),
                      m,
                      f,
                      d[n + 15],
                      16,
                      530742520
                    )),
                    i,
                    m,
                    d[n + 2],
                    23,
                    -995338651
                  )),
                  (r = md5_ii(
                    r,
                    (i = md5_ii(
                      i,
                      (m = md5_ii(m, f, r, i, d[n + 0], 6, -198630844)),
                      f,
                      r,
                      d[n + 7],
                      10,
                      1126891415
                    )),
                    m,
                    f,
                    d[n + 14],
                    15,
                    -1416354905
                  )),
                  i,
                  m,
                  d[n + 5],
                  21,
                  -57434055
                )),
                (r = md5_ii(
                  r,
                  (i = md5_ii(
                    i,
                    (m = md5_ii(m, f, r, i, d[n + 12], 6, 1700485571)),
                    f,
                    r,
                    d[n + 3],
                    10,
                    -1894986606
                  )),
                  m,
                  f,
                  d[n + 10],
                  15,
                  -1051523
                )),
                i,
                m,
                d[n + 1],
                21,
                -2054922799
              )),
              (r = md5_ii(
                r,
                (i = md5_ii(
                  i,
                  (m = md5_ii(m, f, r, i, d[n + 8], 6, 1873313359)),
                  f,
                  r,
                  d[n + 15],
                  10,
                  -30611744
                )),
                m,
                f,
                d[n + 6],
                15,
                -1560198380
              )),
              i,
              m,
              d[n + 13],
              21,
              1309151649
            )),
            (r = md5_ii(
              r,
              (i = md5_ii(
                i,
                (m = md5_ii(m, f, r, i, d[n + 4], 6, -145523070)),
                f,
                r,
                d[n + 11],
                10,
                -1120210379
              )),
              m,
              f,
              d[n + 2],
              15,
              718787259
            )),
            i,
            m,
            d[n + 9],
            21,
            -343485551
          )),
            (m = safe_add(m, h)),
            (f = safe_add(f, t)),
            (r = safe_add(r, g)),
            (i = safe_add(i, e));
        }
        return Array(m, f, r, i);
      }
      function md5_cmn(d, _, m, f, r, i) {
        return safe_add(
          bit_rol(safe_add(safe_add(_, d), safe_add(f, i)), r),
          m
        );
      }
      function md5_ff(d, _, m, f, r, i, n) {
        return md5_cmn((_ & m) | (~_ & f), d, _, r, i, n);
      }
      function md5_gg(d, _, m, f, r, i, n) {
        return md5_cmn((_ & f) | (m & ~f), d, _, r, i, n);
      }
      function md5_hh(d, _, m, f, r, i, n) {
        return md5_cmn(_ ^ m ^ f, d, _, r, i, n);
      }
      function md5_ii(d, _, m, f, r, i, n) {
        return md5_cmn(m ^ (_ | ~f), d, _, r, i, n);
      }
      function safe_add(d, _) {
        var m = (65535 & d) + (65535 & _);
        return (((d >> 16) + (_ >> 16) + (m >> 16)) << 16) | (65535 & m);
      }
      function bit_rol(d, _) {
        return (d << _) | (d >>> (32 - _));
      }
      // end standalone md5 calculus
      const cod_agenzia = this.settings().code.toLowerCase();
      const errors = process.env.VUE_APP_PEASY_ERRORS;
      const privacy = process.env.VUE_APP_PEASY_PRIVACY;
      const str = `${cod_agenzia}${process.env.VUE_APP_PEASY_SALT}`;
      const key = MD5(str);
      let url = "about:blank";
      switch (type) {
        case 1:
          // errori (apre link esterno su nuova pagina)
          // https://www.lisawebonline.com/ERRORS_PRIVACY_LISA01/index.php?AGENZIA=COD_AGENZIA&key=KEY
          url = `https://www.lisawebonline.com/${errors}/index.php?AGENZIA=${cod_agenzia}&key=${key}`;
          break;
        case 2:
          // report (apre link esterno su nuova pagina)
          // https://www.lisawebonline.com/ERRORS_PRIVACY_LISA01/report.php?AGENZIA=COD_AGENZIA&key=KEY
          url = `https://www.lisawebonline.com/${errors}/report.php?AGENZIA=${cod_agenzia}&key=${key}`;
          break;
        case 3:
          // scansioni (apre link esterno su nuova pagina)
          // https://www.lisawebonline.com/PRIVACY_LISA01/index.php?AGENZIA=COD_AGENZIA&key=KEY
          // Nota: può variare anche nell’url PRIVACY_LISA01 con qualcosa di generico (es.: PRIVACY_LISAWEB)
          // COD_AGENZIA: es.: gazmi001
          url = `https://www.lisawebonline.com/${privacy}/index.php?AGENZIA=${cod_agenzia}&key=${key}`;
          break;
        default:
          break;
      }
      window.open(url, target, options);
    },
    // customFilterName(key, prefix = "attributables") {
    //   return `${prefix}.${key}`;
    // },
    onEdit(id, item) {
      this.$router.push({
        name: "registries.edit",
        params: { id: `${id}`, item: item },
      });
    },
    onAdd() {
      this.$router.push({
        name: `${this.resource}.create`,
        params: {
          origin: {
            routeName: "module.PEASY.PRIV",
            filterName: this.filterName,
          },
        },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Anagrafica eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onPrint(/* document */) {
      // download documento privacy precompilato da far firmare all’interessato
      // this.downloadDocument(document);
      this.$showSnackbar({
        preset: "info",
        text: "Download del documento privacy precompilato",
      });
    },
    onDownload(item) {
      // download del documento privacy raccolto se presente
      // nel caso ve ne fosse più di uno, prendere il più recente
      // this.downloadDocument(document);
      let pds = item.documents?.filter((e) => e.document_type.value === 0);
      if (pds.length) {
        const document = pds.reduce((prev, current) =>
          +prev.id > +current.id ? prev : current
        );
        this.downloadDocument(document)
          .then(() => {
            this.$showSnackbar({
              preset: "info",
              text: `Download del documento privacy ${document.title} raccolto`,
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: errMsg,
            });
          });
      } else {
        this.$showSnackbar({
          preset: "info",
          text: `Documento privacy non disponibile`,
        });
      }
    },
    // openNoteModal(array) {
    //   this.notes = array;
    //   this.$bvModal.show("noteDetailModal");
    // },
    // openDocumentModal(array) {
    //   this.documents = array;
    //   this.$bvModal.show("documentDetailModal");
    // },
    onSearch(name) {
      let criteria = this.filter;
      // clear byAttribute.id as before going to regitry.create
      this.filter.byAttribute.id = null;
      // store the filter
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      // fetch data
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", ["settings"]),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
